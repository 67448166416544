@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.sui-search-box__text-input {
  height: auto !important;
  border-radius: 10px !important;
}

.onboard-autocomplete-results-container::-webkit-scrollbar {
  width: 5px;
}

.onboard-autocomplete-results-container::-webkit-scrollbar-thumb {
  border-color: #667085 !important;
  border-radius: 8px;
  border-bottom: 5px solid #ffffff;
  border-top: 5px solid #ffffff;
  border-left: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
}

.container-font-family {
  font-family: 'Inter', sans-serif;
}

.main-autocomplete-search-container {
  margin-top: 0px !important;
}

.main-autocomplete-search-position {
  position: unset;
}
